.nosotros .migas {
  width: 100vw;
}
.b2bnos {
  padding-top: 0;
}
.galSlider {
  overflow: hidden !important;
  display: flex;
  justify-content: center;
  margin: 60px 0 70px;
  border-radius: 50px;
  width: 100%;
}
.galSlider .swiper-wrapper {
  border-radius: 50px;
}
.galSlider .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.galSlider .swiper-slide {
  height: 400px;
}
.galB2B .swiper-slide {
  height: 650px;
}
.nosotros .navSwiper {
  left: initial;
  width: 80%;
  top: 55%;
}
.logoGal {
  position: absolute;
  bottom: -20px;
  width: 300px;
  z-index: 1;
}
.logoSlider {
  position: absolute;
  bottom: -20px;
  width: 200px;
  z-index: 1;
}
.b2bnos .col60 {
  width: 80%;
}
.b2bNav {
  top: 45% !important;
}
/* Media */

@media (max-width: 850px) {
  .nosotros .migas {
    width: 600px;
  }
  .nosotros .navSwiper {
    width: 100%;
    top: 60%;
  }
  .galSlider .swiper-slide {
    height: 350px;
  }
  .logoGal {
    width: 200px;
    bottom: 0;
  }
  .b2bnos .col60 {
    width: 100%;
    position: relative;
  }
  .galB2B {
    margin-top: 20px;
  }
  .logoSlider {
    width: 170px;
    bottom: 0px;
  }
  .b2bNav {
    top: 59% !important;
    width: 100vw !important;
  }
}
