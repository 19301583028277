.footer {
  height: 650px;
  position: relative;
  display: flex;
  align-items: flex-end;
}
.footer .rs {
  align-self: center;
  margin: 20px 0 20px;
}
.logoFooter {
  width: 170px;
  margin-bottom: 30px;
}
.blobFrontF,
.blobBackF {
  position: absolute;
  z-index: -2;
  height: 100%;
  width: 100%;
  min-width: 1200px;
  bottom: 0;
  right: 0;
}
.blobFrontF {
  bottom: -20px;
  z-index: -1;
}
.footer p,
.footer a {
  font-weight: 500;
}
.footer .nav {
  margin-bottom: 20px;
}
p.copy {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
/* Media */

@media (max-width: 850px) {
  .blobFrontF,
  .blobBackF {
    right: -40%;
  }
  .footer .nav {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
  }
}
