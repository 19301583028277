.contacto .migas {
  width: 100vw;
  bottom: 0;
}

.contacto h4 {
  margin-bottom: 0px;
  margin-top: 10px;
  font-weight: 500;
}

.contacto .col60 {
  width: 70%;
  align-items: center;
}

.controlForm {
  row-gap: 20px;
  align-items: center;
  width: 100%;
}

.controlForm .boton {
  align-self: baseline;
  background: #f38552;
}
.controlForm input:not([type="radio"]),
.controlForm textarea {
  width: 100%;
}

.controlForm input,
.controlForm textarea {
  width: auto;
  height: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  border-radius: 15px;
  padding: 16px 25px;
  background: #0000004a;
  color: #fff;
  transition: all 0.3s ease;
}

.controlForm textarea {
  max-height: 200px;
  height: 150px;
  width: 100%;
}

.controlForm input::placeholder,
.controlForm textarea::placeholder {
  color: #fff;
}

.radios input {
  cursor: pointer;
  padding: 13px 25px;
}
.radios:empty {
  display: none;
}

.radios input:checked,
.controlForm input:focus-visible,
.controlForm textarea:focus-visible {
  color: #fff;
  box-shadow: inset 0 0 0 3px var(--maincolor);
  background: #d2373fba;
}

.radios input:before {
  content: attr(label);
  display: inline-block;
  text-align: center;
  width: 100%;
}

.loading {
  padding: 12px 20px;
  width: fit-content;
  border-radius: 12px;
}
.success {
  background: #81c784;
  color: #436444;
}

/* B2B Contact */

.toggleCont h4 {
  margin: 0;
  transition: all 0.5s ease;
  width: 26%;
  cursor: pointer;
}

.toggleCont {
  display: flex;
  width: 73%;
  margin: 20px auto 0;
  align-items: center;
  background: #d2373f;
  padding: 30px 40px;
  border-radius: 30px;
  gap: 30px;
  justify-content: space-between;
}

.toggleCont .SlideBtn {
  height: 30px;
  width: 70px;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  border: none;
  box-shadow: 0px 0px 0px 2px #fff;
  background: transparent;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
}
.toggleCont .titleActive {
  color: #fff;
}
.toggleCont .titleDes {
  color: #fff7;
}
.toggleCont .SlideBtn.is-active {
  background: #fff;
}
.toggleCont .SlideBtn-knob {
  height: 93%;
  border-radius: 20px;
  width: 28px;
  position: absolute;
  background: #fff7;
  left: 1px;
  transition: all 0.5s ease;
}
.toggleCont .is-active .SlideBtn-knob {
  transform: translate(140%, 0px);
  background: #d2373f;
}
.inputsCanal input {
  width: fit-content !important;
  padding: 16px 10px;
  text-align: center;
}
.inputsCanal {
  display: flex;
  width: 100%;
  justify-content: unset;
  gap: 10px;
  flex-wrap: wrap;
}
.inputsCanal,
.radios {
  justify-content: center;
  background: #d2373f69;
  padding: 10px;
  border-radius: 20px;
  width: fit-content;
}
.inputsCanal label {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
  margin-bottom: 0;
}
/* Media */

@media (max-width: 850px) {
  .contacto .col60 {
    width: 100%;
  }
  .controlForm input {
    font-size: 16px;
  }
  .radios {
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 10px;
    row-gap: 10px;
  }
  .toggleCont {
    width: 100%;
    margin: 0;
    padding: 30px 20px;
    gap: 10px;
    flex-direction: row;
  }
  .toggleCont h4 {
    width: 30%;
    font-size: 16px;
  }
  .toggle {
    scale: 0.8;
  }
  .inputsCanal {
    justify-content: center;
  }
  :is(.inputsCanal, .radios) input {
    font-size: 14px;
    padding: 16px 5px;
  }
  .controlForm textarea {
    width: 100%;
  }
}
