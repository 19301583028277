.heroB2B .migas {
  width: 50%;
  top: 30px;
  right: 0;
  z-index: 2;
  rotate: 40deg;
}
.heroB2B .boton {
  width: fit-content;
}

.heroSlider {
  overflow: hidden !important;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  width: 100%;
  min-height: 690px;
  height: 70vh;
  box-shadow: 0px 15px 14px rgb(0 0 0 / 25%);
}
.heroSlider::before {
  content: "";
  position: absolute;
  bottom: -20%;
  left: -20%;
  width: 80%;
  height: 80%;
  border-radius: 0 500px 0 0;
  background: #000000bf;
  z-index: 2;
  filter: blur(110px);
}

.heroSlider .swiper-wrapper {
  border-radius: 50px;
}
.heroSlider .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.heroSlider .swiper-slide {
  height: 100%;
}
.heroDivB2B {
  position: absolute;
  bottom: 0;
  z-index: 3;
  width: 50%;
  margin: 0 0 40px 40px;
}
.heroDivB2B h1 {
  color: #fff;
}

.heroDivB2B p {
  color: #fff;
  opacity: 1;
}
/* Media */

@media (max-width: 850px) {
  .heroB2B {
    padding-bottom: 0;
  }
  .heroSlider .swiper-slide {
    height: 350px;
  }
  .heroSlider {
    min-height: 550px;
    height: 60vh;
  }
  .heroB2B .swiper-slide {
    height: 100% !important;
  }
  .heroDivB2B {
    width: 100%;
  }
  .heroDivB2B {
    width: 80%;
    margin: 0 30px 30px 20px;
  }
  .heroSlider::before {
    filter: none;
    left: 0;
    bottom: 0;
    border-radius: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(20deg, #000000a3, transparent);
  }
  .heroB2B .migas {
    width: 80%;
    top: 200px;
    right: -180px;
    rotate: 10deg;
  }
  .heroB2B h1 {
    font-size: max(6vw, 3rem);
  }
}
