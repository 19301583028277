.lanzamientos {
  background: var(--maincolor);
  border-radius: 32px;
  height: 185px;
  width: calc(100% - 100px) !important;
  margin-left: 100px;
  justify-content: space-evenly;
  padding: 30px 20px 30px 0;
  display: flex;
}

.lanzamientos .info {
  width: 50%;
}

.lanzamientos img {
  width: 30%;
  height: auto;
}

.lanzSlider {
  display: flex;
  justify-content: center;
  padding: 30px 0 70px;
  margin: -10px 0;
}
.prodSlider {
  display: flex;
  justify-content: center;
  padding: 25px 0 50px;
  margin: -10px 0;
}
.prodSlide {
  text-align: center;
}

.swiper-slide {
  opacity: 0 !important;
  visibility: hidden !important;
}

.swiper-slide.swiper-slide-active,
.swiper-slide.swiper-slide-next,
.swiper-slide.swiper-slide-prev {
  opacity: 1 !important;
  visibility: visible !important;
}

.lanzamientos img {
  height: fit-content;
  width: calc(40% + 90px);
  object-fit: contain;
  margin-left: -90px;
  align-self: center;
  transform: rotate(-17deg);
  max-height: 190px;
}

.carruSec .col35 {
  padding-bottom: 60px;
  min-width: 340px;
}

.lanzamientos h4 {
  color: #fff;
}
.lanzamientos p {
  color: #fff;
  opacity: 0.5;
}
.lanzamientos .boton {
  position: absolute;
  bottom: -20px;
  margin-right: 10px;
}
.carruSec .migas {
  width: 40%;
  transform: rotate(90deg);
  top: -30%;
  left: 20%;
}
.prodSlide a img {
  width: 100%;
  max-height: 180px;
  object-fit: contain;
}
.prodSlide {
  justify-content: center;
}
/* Media */

@media (max-width: 850px) {
  .prodSlider,
  .lanzSlider {
    overflow: visible;
  }
  .lanzSlider {
    padding: 30px 0 60px;
  }
  .lanzamientos {
    height: 155px;
  }
  .carruSec .col35 {
    padding-bottom: 20px;
  }
  .navSwiper > div {
    width: 65px;
  }
  .navSwiper {
    width: calc(100% + 30px);
    left: -10px;
  }
}
