.ctaSign {
    background: var(--maincolor);
    padding: 45px 90px;
    border-radius: 50px;
    align-items: center;
    overflow: hidden;
}
.ctaSign p {
    color: #fff;
}
.ctaSign .migas {
    z-index: 1;
    width: 30%;
    top: 10px;
    right: 50px;
    height: 230px;
    transform: rotate(90deg);
}
.ctaSign .boton {
    z-index: 2;
}
/* Media */

@media (max-width: 850px) {
    .ctaSign {
        padding: 45px 60px;
    }
    .ctaSign .migas {
        bottom: -60px;
        top: initial;
        width: 100%;
    }
    .ctaSign .boton {
        margin-top: 20px;
    }
}