.headerB2B {
  padding: 20px 0;
}
.logoB2B {
  width: 140px;
}
.logoH {
  display: flex;
}

.headerB2B a {
  color: #0009;
}
.headerB2B .rs svg path {
  fill: #0009;
}
.headerB2B .menu {
  margin-top: 20px;
}
@media (max-width: 850px) {
  .headerB2B {
    padding: 10px 0 0;
    margin-bottom: -20px;
    position: relative;
    z-index: 10;
  }
  .logoB2B {
    width: 100px;
  }
  .headerB2B .mobileHamb span {
    background-color: #ea0029;
    transition: 0.2s all;
  }
  .headerB2B .hambActive span {
    background: #fff;
  }
  .menuActive a {
    color: #fff;
  }
  .headerB2B .rs svg path {
    fill: #fff;
  }
}
