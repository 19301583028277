.header {
  padding: 50px 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.logo {
  width: 140px;
  height: auto;
  position: relative;
  z-index: 11;
}

.rs {
  margin-bottom: 15px;
  column-gap: 20px;
}

.rs svg {
  width: 23px;
  height: auto;
}

.nav {
  display: flex;
  flex-direction: row;
  column-gap: 40px;
}

.nav a {
  color: #fff;
  font-size: 1.1rem;
  transition: all 0.5s;
}

.nav a:hover {
  opacity: 0.8;
}

.mobileHamb {
  height: 20px;
  border-radius: 50px;
  width: 26px;
  display: none;
  flex-direction: column;
  justify-content: inherit;
  align-self: center;
  position: relative;
  z-index: 11;
}

.mobileHamb span {
  width: 100%;
  height: 2px;
  background-color: #fff;
}
.logoH {
  display: flex;
}

/* Media */
@media (max-width: 850px) {
  .header {
    padding: 20px 0;
  }

  .mobileHamb {
    display: flex;
  }

  .logo {
    width: 100px;
  }

  .menu > div {
    position: relative;
    z-index: 11;
  }
  .menu .rs {
    position: absolute !important;
    bottom: 40px;
  }
  .menu .nav a {
    font-size: 1.5rem;
  }
  .menu {
    transform: translateY(-100%);
    display: flex;
    height: 110%;
    position: fixed;
    width: 100%;
    background: var(--maincolor);
    bottom: 0;
    left: 0;
    z-index: 10;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    transition: cubic-bezier(0.82, -0.33, 0.13, 1.25) all 0.5s;
  }
  .menu.menuActive {
    transform: translateY(0%);
  }
  .menu::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../assets/noise.png);
    opacity: 0.1;
    mix-blend-mode: multiply;
  }

  .menu .nav {
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
  }
}
