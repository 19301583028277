.info .migas {
  width: 100vw;
  transform: rotate(180deg);
  left: -40px;
}

.colImg img {
  position: absolute;
  height: auto;
  bottom: 0;
  right: 0;
}
.colImg {
  height: 400px;
  position: relative;
}
.img12 {
  max-width: 244px;
  width: 60%;
}
.img11 {
  max-width: 442px;
  width: 80%;
}
.img10 {
  max-width: 433px;
  width: 80%;
}

.img20 {
  max-width: 380px;
  width: 80%;
}
.img21 {
  max-width: 485px;
  width: 100%;
}
.img3 {
  width: 100%;
}
.logoCol {
  max-width: 440px;
  width: 60%;
}
.col5.b2bCol {
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
}
/* Media */

@media (max-width: 850px) {
  .b2binfo {
    padding-top: 0;
  }
  .info .migas {
    transform: rotate(0deg);
    top: 20%;
    left: 0;
    height: 1000px;
    width: auto;
  }
  .colImg {
    margin-bottom: 50px;
  }
  .col1 {
    order: 1;
  }
  .col2 {
    order: 2;
  }
  .col3 {
    order: 4;
  }
  .col4 {
    order: 3;
  }
  .col5 {
    order: 5;
  }
  .col6 {
    order: 6;
  }
  .col7 {
    order: 7;
    height: 350px;
  }
  .col8 {
    order: 8;
  }
  .col5.b2bCol {
    margin-bottom: 60px;
    margin-top: 20px;
  }
}
