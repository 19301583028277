.hero {
  min-height: 860px;
  height: 90%;
}
.hero > div,
.hero > div > div {
  height: 100%;
}
.hero h1 {
  align-self: flex-end;
  position: absolute;
}
.blobFront {
  position: absolute;
  top: -80px;
  right: -50px;
  z-index: -1;
  width: 65%;
  height: auto;
  max-width: 1000px;
  min-width: 790px;
}
.blobBack {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
  width: 63%;
  height: auto;
  max-width: 1000px;
  min-width: 780px;
}
.swiper-slide {
  display: flex;
  justify-content: right;
}
.sliderHero {
  width: 100%;
  min-width: 500px;
  overflow: visible !important;
}

.sliderHero img {
  position: absolute;
  top: 18%;
  width: 37%;
  right: 0;
}

.hero .migas {
  top: 10%;
  left: -200px;
  width: 550px;
}
/* Media */

@media (max-width: 850px) {
  .hero {
    min-height: 700px;
    height: 75%;
    max-height: 750px;
  }
  .blobFront {
    width: 80%;
    top: -140px;
    right: -140px;
  }
  .blobBack {
    width: 80%;
    top: -90px;
    right: -90px;
  }
  .sliderHero {
    width: 100%;
    height: 500px;
    overflow: visible !important;
  }
  .sliderHero img {
    top: 10%;
  }
  .hero .migas {
    top: 10%;
    left: 10%;
    width: 400px;
  }
}
